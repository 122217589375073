import SearchBar from '../../components/SearchBar/SearchBar';
import { Stack, Typography } from '@mui/material';
import { useAppDispatch } from '../../utils/hooks';
import { useEffect } from 'react';
import { fetchCategories, fetchProducts } from '../../modules/products/operations';
import ProductList from './Products/ProductList';
import ProductCategoryFilter from './Products/ProductCategoryFilter';
import ProductSearchBar from './Products/ProductSearchBar';
import ProductBottomSheetDialog from './Products/ProductBottomSheetDialog';
import { ReturnButton } from '../../components/styled-components';
import { EloSecondaryButton } from '../../components/BergButtons';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Products = () => {
  const { t } = useTranslation('pages');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, []);
  return (
    <>
      <Stack direction={'column'} spacing={1} sx={{ py: 1 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <EloSecondaryButton onClick={() => navigate('/')}>Powrót</EloSecondaryButton>
          {/*<EloSecondaryButton sx={{ backgroundColor: '#4398D1' }} onClick={() => navigate('/tickets')}>*/}
          {/*  {t('products.buyTicketsButton')}*/}
          {/*</EloSecondaryButton>*/}
        </Stack>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={6}>
            <ProductSearchBar />
          </Grid2>
          <Grid2 xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1rem' }}>{t('products.chooseProductCategoryLabel')}</Typography>
          </Grid2>
        </Grid2>

        <ProductCategoryFilter />
      </Stack>

      <ProductList />
      <ProductBottomSheetDialog />
    </>
  );
};

export default Products;
