import {
  Alert,
  AlertColor,
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BatteryLevel from '../components/BatteryLevel';
import StatBox from '../components/StatBox';
import { ReturnButton, ToolbarReturnButton } from '../components/styled-components';
import { getAppDetails, getDeviceName, getEnabledFeatures } from '../modules/layout/selectors';
import { getStats } from '../modules/tickets/selectors';
import StatsDialog from '../pages/tickets/StatsDialog';
import { useAppSelector } from '../utils/hooks';
import { getReturnUrl } from '../utils/navigationHelper';
import { getTerminal, pairTerminal } from '../utils/terminal';
import useDisplayToolbarReturnButton from './layoutHooks';
import LogoComponent from './LogoComponent';
import i18next from 'i18next';
import Icons from '../assets/icons';
import { Layout } from '../setup/Layout';
import axiosInstance from '../utils/axios';
import { AppFeature } from '../utils/device';

export default function TopAppBar() {
  const { t } = useTranslation(['common']);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const stats = useAppSelector((x) => getStats(x.tickets));
  const deviceName = useAppSelector((x) => getDeviceName(x.layout));
  const appDetails = useAppSelector((x) => getAppDetails(x.layout));
  const enabledFeatures = useAppSelector((x) => getEnabledFeatures(x.layout));
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  let pairingTaps = useRef<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [openStatsDialog, setOpenStatsDialog] = useState<boolean>(false);
  const [type, setType] = useState<AlertColor>('success');
  const [message, setMessage] = useState<string>('');
  const theme = useTheme();
  const isUpMdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const hideLogos = appDetails !== null && appDetails.id === 66411416; // Samsung showroom demo
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18next.language);

  setInterval(() => {
    pairingTaps.current = 0;
  }, 1000 * 60);

  const initPairing = async () => {
    pairingTaps.current++;
    if (pairingTaps.current === 6) {
      console.log('initPairing');
      const terminal = await getTerminal();
      pairingTaps.current = 0;
      pairTerminal()
        .then((response) => {
          console.log(response);
          switch (response) {
            case 'NO_CONNECTION':
              handleOpenSnackBar(
                `Nie znaleziono terminala pod adresem ${terminal.ip} i portem ${terminal.port}.`,
                'error'
              );
              break;
            case 'PAIRING_SUCCESSFUL':
              handleOpenSnackBar('Parowanie zakończone pomyślnie', 'success');
              break;

            default:
              handleOpenSnackBar('Parowanie nieudane. Skontaktuj się z obsługą', 'error');
              break;
          }
        })
        .catch((e) => {
          handleOpenSnackBar('Parowanie nieudane. Skontaktuj się z obsługą', 'error');
          console.error(JSON.stringify(e));
        });
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleOpenSnackBar = (message: string, type: AlertColor) => {
    setMessage(message);
    setType(type);
    setOpen(true);
  };

  const handleClick = () => {
    initPairing();
  };

  const handleStatBoxClick = () => {
    setOpenStatsDialog(true);
  };

  const handleStatDialogCloseClick = () => {
    setOpenStatsDialog(false);
  };

  const handleReturnButtonClick = () => {
    navigate(getReturnUrl(searchParams, '/'));
  };

  const handleChangeLanguage = async (event: SelectChangeEvent) => {
    await i18next.changeLanguage(event.target.value);
  };

  useEffect(() => {
    console.log('Language has been changed', i18next.language);
    axiosInstance.defaults.headers.common['Accept-Language'] = i18next.language;
    setCurrentLanguage(i18next.language);
  }, [i18next.language]);

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <AppBar sx={{ boxShadow: 'none' }}>
        <ResponsiveToolbar sx={{ backgroundColor: 'white' }}>
          <Stack direction="row" justifyContent="space-between" alignItems={'center'} width="100%">
            {!isUpMdScreen && (
              <ToolbarReturnButton
                onClick={handleReturnButtonClick}
                sx={{ alignSelf: 'center', visibility: displayToolbarReturnButton ? 'visible' : 'hidden' }}
              >
                <>{t('common:buttons.back')}</>
              </ToolbarReturnButton>
            )}
            {!hideLogos ? (<LogoComponent openSnackBar={handleOpenSnackBar}></LogoComponent>) : null}
            <Typography sx={{ alignSelf: 'center', color: '#58585A', fontSize: '1rem' }}>{deviceName}</Typography>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent={'center'}>
              {isUpMdScreen && stats[0] && enabledFeatures.includes(AppFeature.TicketSale) ? (
                <StatBox isFirst={true} statsModel={stats[0] ?? {}} onClick={() => handleStatBoxClick()}></StatBox>
              ) : (
                <></>
              )}
              {isUpMdScreen && stats[1] && enabledFeatures.includes(AppFeature.TicketSale) ? (
                <StatBox isFirst={false} statsModel={stats[1] ?? {}} onClick={() => handleStatBoxClick()}></StatBox>
              ) : (
                <></>
              )}
            </Stack>
            {appDetails.layout == Layout.Desktop && appDetails.selfCheckout ? (
              <Select
                value={currentLanguage}
                onChange={handleChangeLanguage}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, height: '60px' }}
              >
                <MenuItem value={'pl'}>
                  <Icons.PlFlag width={'80px'} transform={isUpMdScreen ? 'scale(0.7)' : 'scale(0.5 )'} />
                </MenuItem>
                <MenuItem value={'en'}>
                  <Icons.EnFlag transform={isUpMdScreen ? 'scale(0.8)' : 'scale(0.6 )'} />
                </MenuItem>
                <MenuItem value={'ua'}>
                  <Icons.UaFlag transform={isUpMdScreen ? 'scale(0.7)' : 'scale(0.5 )'} />
                </MenuItem>
              </Select>
            ) : null}
            <Stack direction="row" spacing={2} alignItems="center">
              <BatteryLevel></BatteryLevel>
              {isUpMdScreen && !hideLogos ? (
                <>
                  <Divider orientation="vertical"></Divider>
                  <Box
                    component="img"
                    src="/images/bergregions.png"
                    alt="BergRegions"
                    height={50}
                    onClick={() => handleClick()}
                  ></Box>
                </>
              ) : null}
            </Stack>
          </Stack>
        </ResponsiveToolbar>
      </AppBar>
      <StatsDialog open={openStatsDialog} onClose={() => handleStatDialogCloseClick()} stats={stats}></StatsDialog>
    </>
  );
}

export const ResponsiveToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: 63,
    '& .logo': {
      maxHeight: 63,
    },
  },
  [theme.breakpoints.up('md')]: {
    height: 80,
    '& .logo': {
      maxHeight: 80,
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: 100,
    '& .logo': {
      maxHeight: 100,
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: 128,
    '& .logo': {
      maxHeight: 100,
    },
  },
}));
