import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../setup/Client';
import {
  AmplusClientSetup,
  BgpnClientSetup,
  HawranClientSetup,
  StartveloClientSetup,
  DlugaPolanaClientSetup,
  MeanderClientSetup,
  NoneClientSetup,
  MesseClientSetup,
  PngsClientSetup,
  TpnClientSetup,
} from '../../setup/ClientSetup';
import { Slice } from '../../types';
import { AppDetails, ScannerLocation, ScannerType } from '../../utils/device';
import { LayoutStateModel } from './models';
import { getAppSettings } from './operations';

const initialState = {
  isLoadingDone: false,
  embeddedQrScanner: false,
  embeddedQrScannerLocation: ScannerLocation.none,
  embeddedQrScannerType: ScannerType.none,
  selfCheckout: false,
} as LayoutStateModel;

const layoutSlice = createSlice({
  name: Slice.Layout,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAppSettings.fulfilled, (state, action: PayloadAction<AppDetails>) => {
      state.appDetails = action.payload;
      state.client = action.payload.client;
      state.deviceName = action.payload.deviceName;
      state.layout = action.payload.layout;
      state.enabledFeatures = action.payload.enabledFeatures;
      state.acceptedPaymentTypes = action.payload.acceptedPaymentTypes;
      state.embeddedQrScanner = action.payload.embeddedQrScanner;
      state.embeddedLprScanner = action.payload.embeddedLprScanner;
      state.primaryMode = action.payload.primaryMode;
      state.paymentProvider = action.payload.paymentProvider;
      state.disableAppAnimations = action.payload.disableAppAnimations ?? false;
      state.selfCheckout = action.payload.selfCheckout;
      state.embeddedQrScannerLocation = action.payload.embeddedQrScannerLocation;
      state.embeddedQrScannerType = action.payload.embeddedQrScannerType;
      state.embeddedQrScannerVerticalLocation = action.payload.embeddedQrScannerVerticalLocation;
      state.embeddedQrScannerIconSize = action.payload.embeddedQrScannerIconSize;
      switch (state.client) {
        case Client.PNGS:
          state.clientSetup = PngsClientSetup;
          break;
        case Client.TPN:
          state.clientSetup = TpnClientSetup;
          break;
        case Client.Messe:
          state.clientSetup = MesseClientSetup;
          break;
        case Client.Bgpn:
          state.clientSetup = BgpnClientSetup;
          break;
        case Client.Amplus:
          state.clientSetup = AmplusClientSetup;
          break;
        case Client.Meander:
          state.clientSetup = MeanderClientSetup;
          break;
        case Client.Hawran:
          state.clientSetup = HawranClientSetup;
          break;
        case Client.StartVelo:
          state.clientSetup = StartveloClientSetup;
          break;
        case Client.DlugaPolana:
          state.clientSetup = DlugaPolanaClientSetup;
          break;
        case Client.None:
          state.clientSetup = NoneClientSetup;
          break;
        default:
          console.error(`Cannot map client setup ${state.client}`);
      }
      state.isLoadingDone = true;
    });
  },
});

export const {} = layoutSlice.actions;
export default layoutSlice.reducer;
